import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface DataContextType {
  userData: any;
  setUserData: Dispatch<SetStateAction<any>>;
}

const defaultValue: DataContextType = {
  userData: {},
  setUserData: () => {},
};

export const SessionContext = createContext<DataContextType>(defaultValue);

export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<any>({});

  return (
    <SessionContext.Provider value={{ userData, setUserData }}>{children}</SessionContext.Provider>
  );
};
