import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface NotificationContextType {
  playSound: boolean;
  playType: string;
  setPlaySound: Dispatch<SetStateAction<any>>;
  setPlayType: Dispatch<SetStateAction<any>>;
}

const defaultValue: NotificationContextType = {
  playSound: false,
  playType: 'call',
  setPlaySound: () => {},
  setPlayType: () => {},
};

export const NotificationContext = createContext<NotificationContextType>(defaultValue);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const [playSound, setPlaySound] = useState(false);
  const [playType, setPlayType] = useState('call');
  return (
    <NotificationContext.Provider value={{ playSound, playType, setPlaySound, setPlayType }}>
      {children}
    </NotificationContext.Provider>
  );
};
